/**
* Generated automatically at built-time (2025-04-03T13:29:29.259Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem, isPathfinderAware, mangleTemplateProps } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
const templates=props.templates.map(template=>{if (!isPathfinderAware(template.component, template.props)) {return template;} return {...template,props: {...template.props, ...mangleTemplateProps(template.component, template.props, props.pathfinder)}}})
return mergeTemplateItems(defaultTemplate, templates).map(createLazyTemplateItem);
}
Template.mapValueToProps = value => ({ pathfinder: value.pathfinder });
Template.mapValueToTemplateProps = {pageKey: "vinskap-black-friday",templateKey: "sites/87-0744316a-241c-46c0-9f9b-b69762d1c763"};